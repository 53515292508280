export const routes = {
  HOME: '/',
  LOGIN: '/login',
  REGISTER: '/register',
  COLLECTIONS: '/collections',
  NO_CREATOR: '/no-creator',
  COLLECTIONS_REVIEW: '/collections/review',
  COLLECTIONS_DETAIL: '/collections',
  COLLECTIONS_TYPE: '/collections/type',
  COLLECTIONS_ADD: '/collections/add',
  ANALYTICS: '/analytics',
  ANALYTICS_MEMBERS: '/analytics/members',
  CHALLENGES: '/challenges',
  PROFILE: '/profile',
  PROFILE_WALLET: '/profile/wallet',
  PROFILE_PAYMENT_SETTINGS: '/profile/payment',
  NFTS_ADD: '/nfts/add',
  INTEGRATIONS: '/integrations',
}
