export const queries = {
  COLLECTION_DETAIL: 'COLLECTION_DETAIL',
  SHOP_CONFIG: 'SHOP_CONFIG',
  GET_ACCOUNT_DETAILS: 'GET_ACCOUNT_DETAILS',
  GET_COLLECTIONS: 'GET_COLLECTIONS',
  COLLECTION_LIST: 'COLLECTION_LIST',
  GET_ARTIST_PROFILE: 'GET_ARTIST_PROFILE',
  DROP_PAGE_QUERY: 'DROP_PAGE_QUERY',
  GET_DROP_PAGE: 'GET_DROP_PAGE',
  GET_MEMBERS: 'GET_MEMBERS',
  GET_CHALLENGES: 'GET_CHALLENGES',
  GET_CHALLENGE_TEMPLATES: 'GET_CHALLENGE_TEMPLATES',
  GET_SINGLE_CHALLENGE: 'GET_SINGLE_CHALLENGE',
  GET_SINGLE_CHALLENGE_ENTRY: 'GET_SINGLE_CHALLENGE_ENTRY',
  GET_SINGLE_CHALLENGE_CREATOR: 'GET_SINGLE_CHALLENGE_CREATOR',
  GET_SINGLE_CHALLENGE_FOR_DECISION_WAS_MADE: 'GET_SINGLE_CHALLENGE_FOR_DECISION_WAS_MADE',
  GET_CHALLENGE_REQ: 'GET_CHALLENGE_REQ',
  GET_OWN_ANY_TOKEN_ON_COLLECTION_REQ: 'GET_OWN_ANY_TOKEN_ON_COLLECTION_REQ',
  GET_COLLECTION_NAME_IN_REQ: 'GET_COLLECTION_NAME_IN_REQ',
  GET_SINGLE_REQUIREMENT: 'GET_SINGLE_REQUIREMENT',
  GET_SUBMIT_TIKTOK_VIDEO_REQ: 'GET_SUBMIT_TIKTOK_VIDEO_REQ',
  GET_SPOTIFY_PRE_SAVE_REQ: 'GET_SPOTIFY_PRE_SAVE_REQ',
  GET_SUBMIT_INSTAGRAM_STORY_REQ: 'GET_SUBMIT_INSTAGRAM_STORY_REQ',
  GET_TOKEN_DETAILS: 'GET_TOKEN_DETAILS',
  GET_ARTWORK_URL: 'GET_ARTWORK_URL',
  GET_USER_SPOTIFY: 'GET_USER_SPOTIFY',
}
