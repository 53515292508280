export const colors = {
  primary: {
    accent: '#ADFFB5',
    dark: '#57A871',
    black: '#1A1347',
  },
  secondary: {
    accent: '#907DFE',
    light: '#ECE9FF',
    dark: '#172F38',
  },
  brand: {
    50: '#ebe5ff',
    100: '#c0b3ff',
    200: '#9380fe',
    300: '#744efd',
    400: '#5d1cfb',
    500: '#5205e2',
    600: '#4b02b0',
    700: '#3d017f',
    800: '#29004e',
    900: '#12001e',
  },
  grey: {
    100: '#FAFAFC',
    200: '#F0F1F5',
    300: '#B2B7CF',
    400: '#4F5079',
  },
  common: {
    white: '#FFF',
    gray: '#A0ABB0',
    disabled: '#A0ABB060',
    error: '#F44336',
    success: '#D6FE9B',
    info: '#33A2B8',
    warning: '#FBEDCF',
  },
  purple: {
    100: '#F5F4FF',
    200: '#EDEBFF',
    300: '#DAD7FF',
    400: '#C4C1FF',
    500: '#907DFE',
    600: '#6E5BFF',
    700: '#4D39FF',
    800: '#2C17FF',
    900: '#0A00FF',
  },
  white: '#fff',
  alert: '#F7547B',
}
