import * as React from 'react'
import { createContext, useState } from 'react'

export const CollectionContext: any = createContext<any>({
  collection: null,
  isCollectionFetching: false,
})

// TODO: check if this provider is used anywhere, if it isn't, delete it
export const CollectionProvider = ({ children }: any) => {
  const [isCollectionFetching, setIsCollectionFetching] = useState(false)

  return (
    <CollectionContext.Provider
      value={{
        isCollectionFetching,
        setIsCollectionFetching,
      }}
    >
      {children}
    </CollectionContext.Provider>
  )
}
