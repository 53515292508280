import { api } from '@fuel/core'

export const selectPaymentMethodEndpoint = (dropPageData?: any) => {
  switch (dropPageData?.payment_flow) {
    case 'unique_nft_purchase':
      return api.SELECT_PAYMENT_METHOD

    case 'limited_edition_purchase':
      return api.SELECT_LIMITED_EDITION_PAYMENT_METHOD

    case 'unlimited_edition_purchase':
      return api.SELECT_UNLIMITED_EDITION_PAYMENT_METHOD

    default:
      return api.SELECT_LIMITED_EDITION_PAYMENT_METHOD
  }
}
