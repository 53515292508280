import { useSteps } from 'chakra-ui-steps'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useUserDetails } from '@fuel/core/hooks/useUserDetails'
import { useEditionPurchaseStart } from '../../../hooks/useEditionPurchaseStart'
import { Step1 } from './Steps/Step1'
import { Step2 } from './Steps/Step2'
import { Step3 } from './Steps/Step3'
import { StepperForm } from './StepperForm'
import { PurchaseStatus } from '../../../constants/paymentMethods'
import { PaymentID } from '../../../model/PaymentMethod'

interface Props {
  isTurkish?: boolean
  leaveQueue: () => void
  purchaseStatus: PurchaseStatus
  setPurchaseStatus: any
  purchaseLimit: number
}

const Stepper = ({ isTurkish = false, leaveQueue, purchaseStatus, setPurchaseStatus, purchaseLimit }: Props) => {
  const { nextStep, activeStep, setStep } = useSteps({
    initialStep: 0,
  })
  const [purchaseQuantity, setPurchaseQuantity] = useState<number>(1)
  const [mintPassGroupId, setMintPassGroupId] = useState<string>('')
  const { updateUserDetails } = useUserDetails()
  const [remainingCount, setRemainingCount] = useState<number>(0)

  const [isLeavingPurchase, setIsLeavingPurchase] = useState<boolean>(false)
  const { getEditionPurchaseStatus } = useEditionPurchaseStart({
    setPurchaseStatus,
    purchaseStatus,
    purchaseQuantity,
    setMintPassGroupId,

    setRemainingCount,
  })

  useEffect(() => {
    if (purchaseStatus === PurchaseStatus.NFT_AVAILABLE && !isLeavingPurchase) {
      setStep(1)
    }
  }, [purchaseStatus])

  const handleFirstStep = async () => {
    getEditionPurchaseStatus()
  }

  const methods = useForm({
    reValidateMode: 'onSubmit',
  })

  const steps = [
    {
      content: (
        <Step1
          isTurkish={isTurkish}
          setIsLeavingPurchase={setIsLeavingPurchase}
          isLeavingPurchase={isLeavingPurchase}
          setPurchaseStatus={setPurchaseStatus}
          purchaseStatus={purchaseStatus}
          leaveQueue={leaveQueue}
          purchaseLimit={purchaseLimit}
          purchaseQuantity={purchaseQuantity}
          setPurchaseQuantity={setPurchaseQuantity}
          remainingTickets={remainingCount}
        />
      ),
    },
    {
      content: <Step2 isTurkish={isTurkish} updateUserDetails={updateUserDetails} methods={methods} setIsLeavingPurchase={setIsLeavingPurchase} />,
    },
    {
      content: (
        <Step3
          purchaseQuantity={purchaseQuantity}
          isTurkish={isTurkish}
          updateUserDetails={updateUserDetails}
          mintPassGroupId={mintPassGroupId}
          allowedPaymentIds={[PaymentID.STRIPE, PaymentID.COINBASE]}
        />
      ),
    },
  ]

  return (
    <StepperForm
      isTurkish={isTurkish}
      methods={methods}
      steps={steps}
      setStep={setStep}
      activeStep={activeStep}
      nextStep={nextStep}
      handleFirstStep={handleFirstStep}
      updateUserDetails={updateUserDetails}
      purchaseStatus={purchaseStatus}
      isLeavingPurchase={isLeavingPurchase}
      setIsLeavingPurchase={setIsLeavingPurchase}
      leaveQueue={leaveQueue}
    />
  )
}

export default Stepper
