import { Box, Spinner } from '@chakra-ui/react'
import { StepperAnimation } from '@fuel/core'
import { motion } from 'framer-motion'
import React from 'react'
import { useSelectedPayment } from '../../../../hooks/useSelectedPayment'
import { PaymentID } from '../../../../model/PaymentMethod'
import Payment from '../../Payment/Payment'

interface Props {
  isTurkish: boolean
  updateUserDetails: any
  purchaseQuantity: number
  mintPassGroupId: string
  allowedPaymentIds?: PaymentID[]
}

export const Step3 = ({ isTurkish, updateUserDetails, purchaseQuantity, mintPassGroupId, allowedPaymentIds }: Props) => {
  const { proceedPayment } = useSelectedPayment()

  if (updateUserDetails.status === 'loading') {
    return (
      <Box h={400} display="flex" alignItems="center" justifyContent="center">
        <Spinner />
      </Box>
    )
  }
  return (
    <motion.div
      variants={StepperAnimation}
      initial="enter"
      animate="center"
      exit="exit"
      transition={{
        x: { type: 'spring', stiffness: 300, damping: 30 },
        opacity: { duration: 0.2 },
      }}
      style={{ textAlign: 'center' }}
    >
      <Payment
        purchaseQuantity={purchaseQuantity}
        isTurkish={isTurkish}
        onPaymentSelected={paymentMethod => {
          proceedPayment(paymentMethod, mintPassGroupId)
        }}
        allowedPaymentIds={allowedPaymentIds}
      />
    </motion.div>
  )
}
