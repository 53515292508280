import { useEffect, useRef, useState } from 'react'

/**
 * React 18+ runs useEffect twice by default
 * Using this, we make sure it runs only once for third party libraries
 *
 * @param effect
 */
export const useEffectOnce = (effect: any) => {
  const effectFn = useRef(effect)
  const destroyFn = useRef<any>()
  const effectCalled = useRef(false)
  const rendered = useRef(false)
  const [, refresh] = useState(0)

  if (effectCalled.current) {
    rendered.current = true
  }

  useEffect(() => {
    if (!effectCalled.current) {
      destroyFn.current = effectFn.current()
      effectCalled.current = true
    }

    refresh(1)

    return () => {
      if (!rendered.current) return
      if (destroyFn.current) destroyFn.current()
    }
  }, [])
}
