import { useCallback, useEffect, useState } from 'react'
import { ethers } from 'ethers'
import { useNetworkStatus } from '@fuel/nft'
import {
  checkMerkleProofValidity,
  findTokenIdToMintWithConstraint,
  getBlockNumber,
  getMerkleProof,
  getRawMintPhases,
  fetchTokenIds,
  mintPhasesToMintStatus,
} from '../utils'
import { UniqueCollectionV2MintPhaseType } from '../types'
import { ERC721GatedMintConstraintInitializableV1Abi } from '../constants/abi'

export enum MINT_STATUS {
  // The allowlist is currently active and the user is eligable to mint on it.
  NOW_ON_ALLOW_LIST = 'now_on_allow_list',
  // The allowlist is currently active but the user is not eligable to mint on it.
  NOW_NOT_ON_ALLOW_LIST = 'now_on_not_allow_list',
  // The allowlist is currently public and the user can mint on it.
  NOW_PUBLIC = 'now_public',
  // There is no current phase, but a future public phase
  FUTURE_PUBLIC = 'future_public',
  // there is no current phase, but a future allowlist phase that the user is eligable to mint on
  FUTURE_ON_ALLOW_LIST = 'future_on_allow_list',
  // there is no current phase, but a future allowlist phase that the user is NOT eligable to mint on
  FUTURE_NOT_ON_ALLOW_LIST = 'future_not_on_allow_list',
  // there is no phase at all
  NO_PHASES = 'no_phases',
  // all phases are in the past (drop is over)
  ALL_PHASES_IN_THE_PAST = 'all_phases_in_the_past',
  // fetching status data
  FETCHING_PHASES = 'fetching_phases',
  // error getting phases
  ERROR_FETCHING_PHASES = 'error_fetching_phases',
  // constraint_passed
  CONSTRAINT_PASSED = 'constraint_passed',
  // constraint_not_passed
  CONSTRAINT_NOT_PASSED = 'constraint_not_passed',

  NOW_PUBLIC_CONSTRAINT_PASSED = 'now_public_constraint_passed',
  NOW_PUBLIC_CONSTRAINT_NOT_PASSED = 'now_public_constraint_not_passed',

  FUTURE_PUBLIC_CONSTRAINT_PASSED = 'now_public_constraint_passed',
  FUTURE_PUBLIC_CONSTRAINT_NOT_PASSED = 'now_public_constraint_not_passed',

  // contraint
  // public (passed or not passed)
  // allowlist (passed or not passed)
}

export const NULL_MERKLE_ROOT = '0x0000000000000000000000000000000000000000000000000000000000000000'

interface CollectionPayloadProps {
  collectionId?: string
  uniqueCollectionContractV2: any
  contractAddress: string
  address: string | undefined
}

export const useUniqueCollectionContractV2Payload = ({
  collectionId,
  contractAddress,
  uniqueCollectionContractV2,
  address,
}: CollectionPayloadProps) => {
  const [provider, setProvider] = useState<ethers.providers.Web3Provider | null>(null)
  const { networkStatus } = useNetworkStatus()
  const [mintPayload, setMintPayload] = useState<any>({
    status: MINT_STATUS.FETCHING_PHASES,
    mintPhase: null,
    proof: null,
    isConstraint: false,
  })

  useEffect(() => {
    setMintPayload({
      status: MINT_STATUS.FETCHING_PHASES,
      mintPhase: null,
      proof: null,
      isConstraint: false,
    })
  }, [address])

  useEffect(() => {
    if (window.ethereum) {
      setProvider(new ethers.providers.Web3Provider(window?.ethereum as any))
    }
  }, [address])

  const getMintPayload = useCallback(async () => {
    try {
      if (!provider || !uniqueCollectionContractV2 || !address) return //TODO: Do we need to show something to the user here?
      const blockHeight = await getBlockNumber({ provider, networkStatus })

      if (!blockHeight) return // TODO: set some error state if we dont have a blockHeight
      const rawMintPhases = await getRawMintPhases(uniqueCollectionContractV2)

      // TODO: mintPhasesToMintStatus needs to adjusted so it works with multiple tokenIds from the user
      const payload = await mintPhasesToMintStatus({
        blockHeight,
        rawMintPhases: rawMintPhases,
        checkConstraint: async ({ constraintAddress }) => {
          const constraintContract = new ethers.Contract(constraintAddress, ERC721GatedMintConstraintInitializableV1Abi, provider)
          const tokenIds = await fetchTokenIds({ address }) // TODO: adapt it to the new getTokenIds
          return findTokenIdToMintWithConstraint({ address, tokenIds, constraintContract })
        },
        checkProof: async ({ allowListMintPhase }) => {
          if (!collectionId) {
            return {
              proof: [],
              isValid: false,
            }
          }
          const proof = await getMerkleProof({ collectionId, address, index: allowListMintPhase.mintPhaseIndex })
          const isValid = await checkMerkleProofValidity({ proof, address, index: allowListMintPhase.mintPhaseIndex, uniqueCollectionContractV2 })
          return {
            proof,
            isValid,
          }
        },
      })

      setMintPayload(payload)
    } catch (e) {
      console.error('Something went wrong in useUniqueCollectionContractV2Payload')
    }
  }, [provider, uniqueCollectionContractV2, address])

  useEffect(() => {
    getMintPayload()
  }, [provider, uniqueCollectionContractV2, contractAddress, address])

  return { mintPayload }
}
