import React, { useContext } from 'react'
import { Box, Button, Spinner } from '@chakra-ui/react'
import { PaymentContext } from '@fuel/payment'
import { MagicConnectUserContext } from '@fuel/auth'
import { PurchaseStatus } from '@fuel/payment/constants/paymentMethods'

export const UniqueCollectionPurchaseButton = ({ status, collection }: any) => {
  const { setPaymentModalOpen, purchaseLoading, purchaseStatus } = useContext<any>(PaymentContext)

  const { setCollectionId } = useContext<any>(MagicConnectUserContext)

  const handleSaleClick = async () => {
    setCollectionId(collection.id)
    setPaymentModalOpen(true)
  }

  const SoldOutButton = () => <Button disabled>Sold out</Button>

  const BuyNowButton = () => (purchaseLoading ? <Spinner /> : <Button onClick={() => handleSaleClick()}>Buy now</Button>)

  if (!collection) {
    return (
      <Box ml={10}>
        <Spinner />
      </Box>
    )
  }

  return (
    <>
      {purchaseStatus === PurchaseStatus.SOLD_OUT ? (
        <SoldOutButton />
      ) : status === 'pre_sale' ? (
        <Button disabled>Buy now (presale)</Button>
      ) : status === 'sale' ? (
        <BuyNowButton />
      ) : null}
    </>
  )
}
