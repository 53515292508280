export const api = {
  // Creators
  UPDATE_ARTIST_PROFILE: (id: any) => `/creators/creator_profiles/${id}`,
  GET_ARTIST_PROFILE: `/creators/creator_profiles`,
  CREATE_ARTIST_PROFILE: '/creators/creator_profiles',
  COLLECTIONS: '/creators/collections',
  EDITION_COLLECTIONS: '/creators/editions',
  PUBLISH: '/publish',
  ENABLE_FOR_SALE: '/enable_for_sale',
  NFT: '/creators/nfts',
  CREATE_DROP_PAGE: (collection_id: any) => `/creators/collections/${collection_id}/drop_pages`,
  UPDATE_DROP_PAGE: (collection_id: any, drop_page_id: any) => `/creators/collections/${collection_id}/drop_pages/${drop_page_id}`,
  DROP_PAGE_DETAILS: (collection_id: any, drop_page_id: any) => `/creators/collections/${collection_id}/drop_pages/${drop_page_id}`,
  ANALYTICS_MEMBERS: (searchQuery?: string) => `/creators/analytics/members?search_query=${searchQuery ?? ''}`,
  ANALYTICS_MEMBER_DETAILS: (memberId: string) => `creators/analytics/members/${memberId}`,
  ANALYTICS_DOWNLOAD_MEMBERS: (searchQuery?: string) => `/creators/analytics/members/export?search_query=${searchQuery ?? ''}`,
  GET_CHALLENGES_CREATOR: '/creators/challenges',
  GET_CHALLENGE_TEMPLATES: '/creators/templates',
  PATCH_CHALLENGE_STATUS: (id: string) => `/creators/challenges/${id}/status`,
  GET_CHALLENGES_BY_ID_CREATOR: (challengeId: string) => `/creators/challenges/${challengeId}?expand=entries`,
  GET_CHALLENGE_ENTRY: (entryId: string) => `/creators/entries/${entryId}`,
  SUBMIT_ENTRY_REVIEW: (entryId: string) => `/creators/entries/${entryId}/review`,

  // Drops
  GET_DROP_PAGE: (slug: string) => `users/drop_pages/${slug}`,
  PURCHASE_START: '/users/purchase/start',
  QUEUE_STATUS: '/users/purchase/queue_status',
  QUEUE_LEAVE: '/users/purchase/leave_queue',
  GET_TX_HASH: `/users/purchase/tx_hash`,
  LIMITED_EDITION_PURCHASE_START: '/users/limited_edition_purchase/start',
  SELECT_PAYMENT_METHOD: '/users/purchase/select_payment_method',
  SELECT_LIMITED_EDITION_PAYMENT_METHOD: '/users/limited_edition_purchase/select_payment_method',
  SELECT_UNLIMITED_EDITION_PAYMENT_METHOD: '/users/unlimited_edition_purchase/select_payment_method',
  PAYPAL_CAPTURE_WITH_ORDER_ID: (orderId: string) => `/users/paypal/orders/${orderId}/capture`,
  QUEUE_TICKET_STATUS: '/users/limited_edition_purchase/queue_status',
  LEAVE_TICKET_QUEUE: '/users/limited_edition_purchase/leave_queue',
  GET_TICKET_TX_HASH: `/users/limited_edition_purchase/tx_hash`,
  GET_QUEUE_PURCHASE_STATUS: (edition_id: number) => `/users/limited_edition_purchase/queue_status?edition_id=${edition_id}`,
  GET_MEMBERS: ``,
  GET_CHALLENGES: (creatorId: string) => `/users/creators/${creatorId}/challenges`,
  GET_SINGLE_CHALLENGE: (creatorId: string, challengeId: string) => `/users/creators/${creatorId}/challenges/${challengeId}`,
  GET_SINGLE_REQUIREMENT: (requirementId: string) => `/users/requirements/${requirementId}`,
  SUBMIT_REQUIREMENT: (requirementId: string) => `/users/requirements/${requirementId}/submit`,
  SUBMIT_CHALLENGE: '/creators/challenges',
  DELETE_CHALLENGE: (id: string) => `/creators/challenges/${id}`,

  // Shop
  SHOP_CONFIG: (creator_id: string, id: string) => `/users/creators/${creator_id}/collections/${id}/shop_config`,
  GET_ARTWORK_URL: (artworkId: string) => `/users/files/${artworkId}/url`,
  UPLOAD_FILE: '/users/files',

  COLLECTION: (creator_id: string, id: string) => `/users/creators/${creator_id}/collections/${id}`,
  OWNED_TOKENS_ON_COLLECTION: (creator_id: string, collection_id: string, owner_address: string) =>
    `/users/creators/${creator_id}/collections/${collection_id}/owned_tokens?owner_address=${owner_address}`,

  // Authentication
  CONNECT_WALLET: '/users/connect_wallet',
  VERIFY_SIGNATURE: '/users/verify_signature',
  CONNECT_WALLET_DID: '/users/connect_wallet_did',
  REFRESH_TOKEN: '/users/refresh_token',
  GET_ACCOUNT_DETAILS: '/users/account',
  VERIFY_PHONE: '/users/verify_phone',
  VERIFY_OTP: '/users/verify_otp',
  EMAIL_VERIFICATION: '/users/verify_email',
  DISCORD_NAME: '/users/discord',
  GET_USER_SPOTIFY: '/users/spotify',
  DELETE_SPOTIFY_USER: '/users/spotify/logout',

  // Allowlist proof
  ALLOW_LIST_PROOF: '/users/allowlist_proof',

  // Token Ids
  TOKEN_IDS: '/users/token_ids',
}
