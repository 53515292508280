import { CreateOrderData, CreateOrderActions, OnApproveData, OnApproveActions } from '@paypal/paypal-js/types/components/buttons'
import { api, getLocalStorage, routes, storage } from '@fuel/core'
import { PaymentID } from '../model/PaymentMethod'
import { useAxiosWithAuth } from '@fuel/api'
import { PayPalPayloadType } from '../model/PaymentPayloadType'
import { urls } from '@fuel/stepper/constants/urls'
import { selectPaymentMethodEndpoint } from '../utils/selectPaymentMethodEndpoint'
import { setLocalStorage } from '@fuel/core/services/localStorage'
import { useToast } from '@chakra-ui/react'

const showToastError = (toast: any) => {
  toast({
    title: 'PayPal payment aborted. Please try again later.',
    status: 'error',
    duration: 3000,
  })
}

interface Props {
  purchaseQuantity?: string
  dropPageData?: any
  mintPassGroupId?: string
}

export const usePayPal = ({ purchaseQuantity, dropPageData, mintPassGroupId }: Props) => {
  const axiosApi = useAxiosWithAuth()
  const toast = useToast()

  const createOrder = async (data: CreateOrderData, actions: CreateOrderActions) => {
    try {
      const selectedEdition = getLocalStorage(storage.SELECTED_EDITION)
      if (!selectedEdition?.id) return showToastError(toast)

      const quantity = purchaseQuantity ?? '1'
      const endpoint = selectPaymentMethodEndpoint(dropPageData)

      let payload: PayPalPayloadType = {
        payment_method: PaymentID.PAYPAL,
        cancel_url: window.location.origin + urls.CANCEL_URL,
        redirect_url: window.location.origin + urls.REDIRECT_URL,
        quantity,
        ...(mintPassGroupId && { mint_pass_group_id: mintPassGroupId }),
      }
      payload.edition_id = selectedEdition?.id

      return axiosApi.post(endpoint, payload).then(({ data }) => {
        if (data.mint_pass_id) setLocalStorage(storage.MINT_PASS_ID, data.mint_pass_id)
        return data.order_id
      })
    } catch {
      showToastError(toast)
    }
  }

  const onApprove = async (data: OnApproveData, actions: OnApproveActions) => {
    try {
      const mintPassId = getLocalStorage(storage.MINT_PASS_ID)
      const orderId = data.orderID

      if (!mintPassId || !orderId) return showToastError(toast)
      const response = await axiosApi.post(api.PAYPAL_CAPTURE_WITH_ORDER_ID(orderId), { mint_pass_id: mintPassId })

      switch (response.status) {
        case 200:
          return (window.location.href = `${routes.HOME}?success=true`)
        case 422:
          return response.data.code === 1091 ? actions.restart() : showToastError(toast)
        default:
          showToastError(toast)
      }
    } catch {
      showToastError(toast)
    }
  }

  const onCancel = async () => {
    const selectedEdition = getLocalStorage(storage.SELECTED_EDITION)
    if (!selectedEdition?.id) return showToastError(toast)

    await axiosApi.post(api.QUEUE_LEAVE, { collection_id: selectedEdition.id }) // if we want to handle what happens when user cancels the payment e.g. closes the PayPal payment modal
    window.location.reload()
  }

  const onError = () => {
    return showToastError(toast)
  }

  return {
    createOrder,
    onApprove,
    onCancel,
    onError,
  }
}
