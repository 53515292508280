import { Box, Flex, Icon, Image, Radio, RadioGroup, Stack, Text } from '@chakra-ui/react'
import { paymentMethods } from '../../../../constants/paymentMethods'
import { turkishPaymentMethods } from '../../../../constants/turkishPaymentMethodsTranslation'
import { StyledHStack } from './PaymentMethodsList.styles'
import { useTranslation } from 'next-i18next'
import { PaymentID } from '../../../../model/PaymentMethod'

type RadioButtonsProps = {
  value: string
  setValue: (value: string) => void
  isTurkish?: boolean
  allowedPaymentIds?: PaymentID[]
}

export default function PaymentMethodsList({ setValue, value, isTurkish, allowedPaymentIds }: RadioButtonsProps) {
  const { t } = useTranslation('stepper')
  let paymentMethodsWithLanguage = isTurkish ? turkishPaymentMethods : paymentMethods

  if (allowedPaymentIds?.length) {
    paymentMethodsWithLanguage = paymentMethodsWithLanguage.filter(paymentMethod => allowedPaymentIds.includes(paymentMethod.payment_id))
  }

  return (
    <RadioGroup defaultValue="2" onChange={setValue} value={value}>
      <Stack spacing="15px" transition="all .2s">
        {paymentMethodsWithLanguage.map(({ id, title, subtitle, recommended, icon }) => {
          return (
            <StyledHStack onClick={() => setValue(id)} key={id}>
              <Flex alignItems="center" bg="#E8ECFB" w="34px" h="34px" borderRadius="10px">
                {typeof icon === 'string' ? <Image margin="0 auto" src={icon} /> : <Icon margin="0 auto" as={icon} color="purple.500" />}
              </Flex>
              <Box w="100%" display="flex" justifyContent="space-between">
                <Box>
                  <Text color="#E8ECFB" lineHeight="24px" fontSize="14px" fontWeight={600} fontFamily="Inter">
                    {t(title)}
                    {recommended && (
                      <Text as="span" color="#CFD4EB" fontSize="10px" lineHeight="12px" fontWeight={500}>
                        {' '}
                        ({isTurkish ? 'önerilen' : 'Recommended'})
                      </Text>
                    )}
                  </Text>
                  <Text color="#CFD4EB" fontFamily="Inter" fontWeight={500} lineHeight="16px" fontSize="10px">
                    {t(subtitle)}
                  </Text>
                </Box>
                <Radio value={id} colorScheme="white" border="2px solid green" />
              </Box>
            </StyledHStack>
          )
        })}
      </Stack>
    </RadioGroup>
  )
}
