import { urls } from '../constants/urls'
import { toast } from 'react-hot-toast'
import { useAxiosWithAuth } from '@fuel/api'
import { getLocalStorage, setLocalStorage } from '@fuel/core/services/localStorage'
import { MagicConnectUserContext } from '@fuel/auth'
import { useContext } from 'react'
import { DropPageDataContext } from '@fuel/auth/context/DropPageDataProvider'
import { api, storage } from '@fuel/core'
import { useRouter } from 'next/router'

/**
 * @deprecated: Please use useSelectedPayment from stepper package
 */
export const useSelectedPayment = () => {
  const axiosApi = useAxiosWithAuth()
  const { collectionId } = useContext<any>(MagicConnectUserContext)
  const selectedEdition = getLocalStorage(storage.SELECTED_EDITION)
  const { dropPageData } = useContext<any>(DropPageDataContext)
  const router = useRouter()

  const selectPaymenMethodEnpoint = () => {
    switch (dropPageData?.payment_flow) {
      case 'unique_nft_purchase':
        return api.SELECT_PAYMENT_METHOD

      case 'limited_edition_purchase':
        return api.SELECT_LIMITED_EDITION_PAYMENT_METHOD

      case 'unlimited_edition_purchase':
        return api.SELECT_UNLIMITED_EDITION_PAYMENT_METHOD

      default:
        return api.SELECT_LIMITED_EDITION_PAYMENT_METHOD
    }
  }

  const proceedPayment = async (payment_method: string, mint_pass_group_id?: string) => {
    type PayloadType = {
      payment_method: string
      cancel_url: string
      redirect_url: string
      edition_id?: string
      collection_id?: string
      mint_pass_group_id?: string
    }

    try {
      let payload: PayloadType = {
        payment_method,
        cancel_url: window.location.href + urls.CANCEL_URL,
        redirect_url: window.location.href + urls.REDIRECT_URL,
        mint_pass_group_id,
      }

      if (selectedEdition?.id) payload.edition_id = selectedEdition?.id
      if (collectionId) payload.collection_id = collectionId

      const { data } = await axiosApi.post(selectPaymenMethodEnpoint(), payload)
      const mintPassId = data.mint_pass_id

      if (mintPassId) setLocalStorage(storage.MINT_PASS_ID, mintPassId)

      router.push(data.hosted_url)
    } catch (e) {
      toast.error('Something went wrong. Please try again later.')
    }
  }

  return { proceedPayment }
}
