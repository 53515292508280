import { useContext, useState } from 'react'
import { verifyDidToken } from '../utils'
import { PaymentContext } from '@fuel/payment'
import { MagicConnectUserContext } from '@fuel/auth/context/MagicConnectUserProvider'
import { api } from '@fuel/core'
import { useAxiosWithAuth } from '@fuel/api'
import { useUserDetails } from '@fuel/core/hooks/useUserDetails'
import { InstanceWithExtensions } from '@magic-sdk/provider/dist/types/modules/base-extension'
import { MagicSDKExtensionsOption, SDKBase } from '@magic-sdk/provider/dist/types/core/sdk'

interface UseMagicConnectArgs {
  setDisabled?: any
  callback?: any
}

export const useMagicConnect = ({ setDisabled, callback }: UseMagicConnectArgs) => {
  const [loading, setLoading] = useState(false)

  const { setUserDetails } = useContext<any>(MagicConnectUserContext)
  const magic = useContext<any>(MagicConnectUserContext).magic as InstanceWithExtensions<SDKBase, MagicSDKExtensionsOption<string>>
  const { setPurchaseLoading } = useContext<any>(PaymentContext)
  const axiosApi = useAxiosWithAuth()

  const getUserInfo = async () => {
    try {
      const { data } = await axiosApi.get(api.GET_ACCOUNT_DETAILS)
      if (data) {
        setUserDetails(data)
        callback && callback()
      }
    } catch (e) {
      console.error('Get User Info ERROR:', e)
    }
  }

  const getEmail = async (): Promise<string | undefined> => {
    try {
      const { email } = await magic.user.requestInfoWithUI({ scope: { email: 'required' } })

      return email
    } catch (e) {
      window.location.reload()
    }
  }

  /**
   * Initializes a connection to the magic wallet's UI and listens for the creation
   * of a DID token (https://magic.link/docs/auth/introduction/decentralized-id).
   * Once the token is created, the user's email and the token is sent to the backend, the token gets verified.
   */
  const connectMagic = async () => {
    try {
      // magic only sends us a did token when the user is logged out
      await magic.user.logout()
      setPurchaseLoading(true)
      setLoading(true)

      await magic.wallet.connectWithUI().on('id-token-created', async (params: any) => {
        const email = await getEmail()
        const { authenticated } = await verifyDidToken(params.idToken, email)

        if (authenticated) {
          setDisabled ? setDisabled(false) : null
        }

        getUserInfo()
      })

      // Has address from Magic connect = is magic
      /*if (walletInfo.length) {
         getUserInfo()
      } else {
        // in metamask
        await signMessage(accounts[0])
        getUserInfo()

        if (!email?.length) {
          const email = await getEmail()
          setEmail(email)

          const newData = {
            email,
          }
          updateUserDetails.mutate(newData)
        }
      }*/
    } catch (e) {
      window.location.reload()
      console.log('Error magic connect', e)
    } finally {
      setLoading(false)
      setPurchaseLoading(false)
      setDisabled && setDisabled(false)
    }
  }

  return { connectMagic, loading }
}
