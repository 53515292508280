export const Button = {
  variants: {
    primary: {
      bg: 'primary.accent',
      color: 'grey.400',
      fontSize: '16px',
      borderRadius: '12px',
      _hover: {
        _loading: {
          bg: 'primary.accent',
        },
        _disabled: {
          bg: 'primary.accent',
        },
      },
    },
    secondary: {
      color: 'secondary.accent',
      bg: 'secondary.light',
      fontSize: '16px',
      borderRadius: '12px',
    },
    secondary_no_bg: {
      color: 'secondary.accent',
      bg: 'transparent',
      fontSize: '16px',
      borderRadius: '12px',
    },
    tertiary: {
      color: 'grey.400',
      bg: 'grey.200',
      fontSize: '16px',
      borderRadius: '12px',
    },
    link: {
      color: 'secondary.accent',
      fontWeight: 900,
    },
  },
}
