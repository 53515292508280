import { MINT_STATUS } from '../hooks/useUniqueCollectionContractV2Payload'

interface HandleSetItemsArgs {
  mintPayload: any
  setItems: any
  uniqueCollectionContractV2: any
  address: string | undefined
}

export const handleSetItems = async ({ mintPayload, setItems, uniqueCollectionContractV2, address }: HandleSetItemsArgs) => {
  if (mintPayload.status == MINT_STATUS.NOW_PUBLIC) {
    setItems([])
    return
  } else {
    setItems([
      {
        text: 'Genesis Pass Holder',
        success: true,
        loading: false,
        errorText: 'you are not a Genesis Pass Holder',
      },
      {
        text: 'Free NFT unclaimed',
        success: false,
        loading: false,
        errorText: 'you have already claimed your free NFT',
      },
    ])
  }
}
