import 'vanilla-cookieconsent'
import { useEffectOnce } from '../hooks/useEffectOnce'
import { FC } from 'react'

type Props = {
  pluginConfig: UserConfig
}

export const CookieConsent: FC<Props> = ({ pluginConfig }) => {
  useEffectOnce(() => {
    document.body.classList.toggle('c_darkmode')
    // @ts-ignore
    window.CookieConsentApi = window.initCookieConsent()
    // @ts-ignore
    window.CookieConsentApi.run(pluginConfig)
  })

  return null
}
