import React, { FC, useEffect } from 'react'
import { useAccount, useNetwork } from 'wagmi'
import { Button } from '@chakra-ui/react'
import { SaleStatus } from '@fuel/core'
import { useCurrentBlockHeight, MINT_STATUS } from './useCurrentBlockHeight'
import { useNetworkStatus, NETWORK_STATUS } from './useNetworkStatus'
import { useGetMerkleRoot } from './useGetMerkleRoot'
import { useMint } from './useMint'
import { useMintSoldOut, useMintExceptions, useMintPerAddress } from '@fuel/nft'
import { SucceedBuyModal } from '../../components/SucceedBuyModal'
import { AuthRainbowKitButton } from '@fuel/rainbow'

interface MintButtonProps {
  gasPrice: string
  isFetching: boolean
  collection: any
  buttonStyles?: any
}

export const MintButton: FC<MintButtonProps> = ({ gasPrice, isFetching, collection, buttonStyles }) => {
  const { mintStatus } = useCurrentBlockHeight()
  const { proof, proofValid } = useGetMerkleRoot(mintStatus)
  const { networkStatus } = useNetworkStatus()
  const { mintToken, processing } = useMint({ proof, mintStatus })
  const { soldOut } = useMintSoldOut()
  const { hasEnoughFunds } = useMintExceptions(gasPrice, proof, mintStatus)
  const { isMaxMinted } = useMintPerAddress(mintStatus)
  const { chain } = useNetwork()
  const { address, isConnecting } = useAccount()
  const [hasMounted, setHasMounted] = React.useState(false)

  let cta

  useEffect(() => {
    setHasMounted(true)
  }, [])

  if (!hasMounted) {
    return null
  }

  const hasWalletAddress = !isConnecting && address

  if (address) {
    cta = (
      <Button onClick={mintToken} sx={buttonStyles} disabled={processing || isFetching}>
        {processing ? 'Processing...' : 'Mint now'}
      </Button>
    )
  }

  if (!hasEnoughFunds) {
    cta = (
      <Button disabled sx={buttonStyles}>
        Insufficient funds!
      </Button>
    )
  }

  if (isMaxMinted) {
    cta = (
      <Button disabled sx={buttonStyles}>
        Minted NFT Limit Reached
      </Button>
    )
  }

  if (soldOut) {
    cta = (
      <Button disabled sx={buttonStyles}>
        Sold out
      </Button>
    )
  }

  if (!hasWalletAddress || chain?.unsupported) {
    cta = <AuthRainbowKitButton buttonStyles={buttonStyles} />
  }

  if ((!proof || !proofValid) && mintStatus === MINT_STATUS.ALLOW_LIST) {
    cta = (
      <Button disabled sx={buttonStyles}>
        You are not on the allowlist
      </Button>
    )
  }

  if (
    !isFetching &&
    hasWalletAddress &&
    networkStatus === NETWORK_STATUS.CORRECT &&
    (collection?.sale_status === SaleStatus.DISABLED || mintStatus === MINT_STATUS.DISABLED)
  ) {
    cta = (
      <Button disabled sx={buttonStyles}>
        Sale not started
      </Button>
    )
  }

  return (
    <>
      {cta}

      <SucceedBuyModal />
    </>
  )
}
