import { Spinner, Box, Text } from '@chakra-ui/react'
import React from 'react'
import { PurchaseStatus } from '../constants/paymentMethods'

export function WaitingForToken({
  setPurchaseStatus,
  isTurkish = false,
  setIsLeavingPurchase,
}: {
  isTurkish?: boolean
  setPurchaseStatus: (value: PurchaseStatus) => void
  setIsLeavingPurchase: (value: boolean) => void
}) {
  return (
    <Box textAlign="center">
      <Box py="50px">
        <Spinner thickness="15px" speed="1s" emptyColor="gray.300" color="alt.main" boxSize={24} />
      </Box>
      <Text fontWeight={800} lineHeight="44px" fontSize="28px" color="#E8ECFB">
        {isTurkish ? 'Sıradasın' : `Warteschlange`}
      </Text>
      <Text fontWeight={500} lineHeight="24px" fontSize="16px" color="#E8ECFB" mt="10px">
        {isTurkish
          ? `Bir yer açılırsa,işlemi tamamlaman için seni içeri alacağız.." to "neredeyse bitti, sayfayı yenileme veya kapatma.Birazdan işlem tamamlanmış olacak.`
          : `Habe kurz Geduld, gleich geht es weiter. Bitte aktualisiere oder schließe die Seite nicht.`}
      </Text>

      <Text
        fontWeight={600}
        lineHeight="24px"
        fontSize="16px"
        color="alt.main"
        mt="30px"
        letterSpacing="-0.5px"
        pb="30px"
        _hover={{
          textDecoration: 'underline',
          cursor: 'pointer',
        }}
        onClick={() => {
          setIsLeavingPurchase(true)
          setPurchaseStatus(PurchaseStatus.NOT_IN_QUEUE)
        }}
      >
        {isTurkish ? `Sırayı terk et ?` : `Abbrechen`}
      </Text>
    </Box>
  )
}
