import { useMutation } from 'react-query'
import { useAxios, useAxiosWithAuth } from '@fuel/api'

export const useUserDetails = () => {
  const axiosApi = useAxiosWithAuth()
  const updateUserDetails = useMutation(async (data: any) => {
    try {
      const { data: response } = await axiosApi.patch('/users/account', data)
      return response
    } catch (e) {
      console.log(e)
    }
  })
  return { updateUserDetails }
}

export const useUserDetailsNoAuth = () => {
  const axiosApi = useAxios()
  const updateUserDetails = useMutation(async (data: any) => {
    try {
      const { data: response } = await axiosApi.post('/users/account', data)
      return response
    } catch (e) {
      console.log(e)
    }
  })
  return { updateUserDetails }
}
