import { PayPalButtons } from '@paypal/react-paypal-js'

/**
 * When using this button, make sure to wrap it in a PayPalScriptProvider from @fuel/payment
 */
export const PayPalButton = (props: any) => {
  return (
    <PayPalButtons
      {...props}
      style={{
        layout: 'horizontal',
        shape: 'pill',
        height: 40,
      }}
    />
  )
}
