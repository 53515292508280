import React, { FC, ReactNode, useContext, useEffect } from 'react'
import { MagicConnectUserContext } from '@fuel/auth'

type IntercomContainerProps = {
  children: ReactNode
}

export const IntercomContainer: FC<IntercomContainerProps> = ({ children }) => {
  const { userDetails, magic } = useContext<any>(MagicConnectUserContext)

  useEffect(() => {
    ;(async () => {
      if (userDetails?.wallet_public_address && magic?.connect) {
        try {
          const { email } = await magic?.connect.requestUserInfo({
            isResponseRequired: true,
          })

          if (typeof window !== 'undefined' && (window as any).Intercom && userDetails?.wallet_public_address) {
            ;(window as any).Intercom('update', {
              email: email ?? '', // Email address only from magic connect
              user_id: userDetails?.wallet_public_address,
            })
          }
        } catch (e) {
          console.error(e)
        }
      }
    })()
  }, [userDetails, magic?.connect])

  return <>{children}</>
}
